import * as React from 'react';
import * as CSS from 'csstype';
// import { Link } from 'gatsby';
// import { withMixpanel } from 'gatsby-plugin-mixpanel';

import Layout from '../components/layout';
// import Image from '../components/image';
import SEO from '../components/seo';

const Container: CSS.Properties = {
  height: `calc(100vh - 50px)`,
  display: `flex`,
  justifyContent: `center`,
  flexDirection: `column`,
  alignItems: `center`,
};

const IndexPage = () => (
  <Layout>
    <SEO title="Mika Rehman" description="Mika Rehman | Javascript Developer" />
    <div style={Container}>
      <h1>mikarehman.com</h1>
      <h2>Javascript Developer</h2>
      <div style={{ padding: `20px` }}>
        <a href="https://www.linkedin.com/in/mika-rehman/">Linkedin</a>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
